//! Paket karşılaştırmasında gözüken sütunlar burada ayarlanmıştır.
export const plans = [
    {
      title: "Gelişmiş Araçlar",
      type: "main",
      free: "",
      standard: "",
      premium: "",
    },
    {
      title: "Codemino(290 Aşama)",
      type: null,
      free: "restricted",
      standard: true,
      premium: true,
    },
    {
      title: "CodeBoo(50 Aşama)",
      type: null,
      free: "restricted",
      standard: true,
      premium: true,
    },
    {
      title: "CodeÇizz (FreeStyle)",
      type: null,
      free: false,
      standard: true,
      premium: true,
    },
    {
      title: "CodeArt (FreeStyle)",
      type: null,
      free: false,
      standard: true,
      premium: true,
    },
    {
      title: "CodeLab (150 Aşama)",
      type: null,
      free: false,
      standard: false,
      premium: true,
    },
    {
      title: "CodeÇizz (80 Aşama)",
      type: null,
      free: false,
      standard: false,
      premium: true,
    },
    // {
    //   title: "Robot14 (50 Aşama)",
    //   type: null,
    //   free: false,
    //   standard: false,
    //   premium: true,
    // },
    // {
    //   title: "Robot14 (Simülasyon)",
    //   type: null,
    //   free: false,
    //   standard: false,
    //   premium: true,
    // },
    // {
    //   title: "Cyber14",
    //   type: null,
    //   free: false,
    //   standard: false,
    //   premium: true,
    // },
    {
      title: "Öğrenme Patikaları (Temel)",
      type: "main",
      free: "",
      standard: "",
      premium: "",
    },
    {
      title: "Bilgisayarımı Öğreniyorum (131 Video)",
      type: null,
      free: true,
      standard: true,
      premium: true,
    },
    {
      title: "Bilişime İlk Adım - Scratch ( 85 Video)",
      type: null,
      free: "restricted",
      standard: true,
      premium: true,
    },
    {
      title: "Bilişime İlk Adım - Lua (62 Video)",
      type: null,
      free: false,
      standard: true,
      premium: true,
    },
    {
      title: "Pinoo İle İcat Çıkar (69 Video) (Partner)",
      type: null,
      free: false,
      standard: true,
      premium: true,
    },
    {
      title: "Öğrenme Patikaları (Orta)",
      type: "main",
      free: "",
      standard: "",
      premium: "",
    },
    {
      title: "Oyun Programlama (144 Video)",
      type: null,
      free: "restricted",
      standard: true,
      premium: true,
    },
    {
      title: "Yapay Zeka (120 Video)",
      type: null,
      free: "restricted",
      standard: true,
      premium: true,
    },
    {
      title: "Web Programlama (127 Video)",
      type: null,
      free: false,
      standard: true,
      premium: true,
    },
    {
      title: "Siber Güvenlik (263 Video)",
      type: null,
      free: false,
      standard: true,
      premium: true,
    },
    {
      title: "Ardunio ile Robotik Kodlama (46 Video)",
      type: null,
      free: false,
      standard: true,
      premium: true,
    },
    {
      title: "Öğrenme Patikaları (İleri)",
      type: "main",
      free: "",
      standard: "",
      premium: "",
    },
    {
      title: "Oyun Programlama (109 Video)",
      type: null,
      free: false,
      standard: false,
      premium: true,
    },
    {
      title: "Yapay Zeka (105 Vİdeo)",
      type: null,
      free: false,
      standard: false,
      premium: true,
    },
    {
      title: "Web Programlama (168 Vİdeo)",
      type: null,
      free: false,
      standard: false,
      premium: true,
    },
    {
      title: "Siber Güvenlik (157 Video)",
      type: null,
      free: false,
      standard: false,
      premium: true,
    },
    {
      title: "Gelişim Paneli",
      type: "main",
      free: true,
      standard: true,
      premium: true,
    },
    {
      title: "Ödül Sistemi",
      type: null,
      free: "restricted",
      standard: true,
      premium: true,
    },
    {
      title: "Liderlik Sistemi",
      type: null,
      free: "restricted",
      standard: true,
      premium: true,
    },
    {
      title: "Sertifika Sistemi",
      type: null,
      free: false,
      standard: true,
      premium: true,
    },
    {
      title: "Veli Bilgilendirme Sistemi",
      type: null,
      free: false,
      standard: true,
      premium: true,
    },
    {
      title: "TALENT-HUB",
      type: null,
      free: false,
      standard: false,
      premium: true,
    },
    {
      title: "TALENT-CUP",
      type: null,
      free: false,
      standard: false,
      premium: true,
    },
    // {
    //   title: "Mentör Desteği",
    //   type: "main",
    //   free: "",
    //   standard: "",
    //   premium: "",
    // },
    // {
    //   title: "Kariyer Danışmanı",
    //   type: null,
    //   free: false,
    //   standard: false,
    //   premium: true,
    // },
    // {
    //   title: "Canlı Destek",
    //   type: null,
    //   free: false,
    //   standard: false,
    //   premium: true,
    // },
  ];