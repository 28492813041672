import React from "react";
import style from "./Section2Card.module.css";

export const Content = ({img, title, desc}) => {
  return (
    <div className={`${style.wrapper}`}>
    <div className={`${style.content}`}>
      <img className={`${style.icon}`} alt="Icon" src={img} />

      <div className={`${style.text}`}>
        <div className={`${style.contentTitle}`}>{title}</div>
        <p className={`${style.description}`}>
          {desc}
        </p>

      </div>
    </div>
    </div>
  );
};
