import React from "react";
import style from "./Section1.module.css";
import Header from "../../../../components/Header";
import talent14Logo2 from "../../../../assets/TALENT14_LOGO_2.png";
import hamburgerIcon from "../../../../assets/hamburgerMenu2.png"
import circle1 from "../../../../assets/circle1.png";
import circle2 from "../../../../assets/circle2.png";
import circle3 from "../../../../assets/circle2.png";

const Section1 = () => {
  return (
    <div className={`${style.section1} m-0`}>
      <div className={`${style.section1Header}`}>
        <Header logo={talent14Logo2} hamburgerIcon={hamburgerIcon}/>
      </div>
      <img
        src={circle1}
        className={`${style.circle1}`}
        alt="Ön Plan Görüntüsü"
      />
      <img
        src={circle2}
        className={`${style.circle2}`}
        alt="Ön Plan Görüntüsü"
      />
      <img
        src={circle3}
        className={`${style.circle3}`}
        alt="Ön Plan Görüntüsü"
      />

      <div className={`${style.center}`}>
        <p className={`${style.title} m-0`}>Platform</p>
        <p className={`${style.description} m-0`}>
          TALENT FOR TEEN, kodlama öğrenmenin yanı sıra, eğlenceli ve kolay bir
          şekilde öğrendiklerini uygulayarak kalıcı hale getirebildiğiniz eğitim
          platformudur.
        </p>
      </div>
    </div>
  );
};

export default Section1;
