import icon1 from "../../../../assets/icon1.png";
import icon3 from "../../../../assets/icon3.png";
import icon4 from "../../../../assets/icon4.png";
import icon5 from "../../../../assets/icon5.png";
import icon6 from "../../../../assets/icon6.png";
export const section2cards = [
  { id:1, image: icon1, title: "Oyun Kodlama", description: "Bilgisayar oyunlarını tasarlamak ve oluşturmak için programlama dillerini kullanabilme yeteneği." },
  { id:2, image: icon1, title: "Mobil Uygulama", description: "Akıllı telefonlar ve tabletler için uygulama geliştirme becerisi." },
  { id:3, image: icon3, title: "Yapay Zeka", description: "Bilgisayar sistemlerine öğrenme, karar verme ve problem çözme yetenekleri kazandırmak için algoritmalar geliştirme." },
  { id:4, image: icon4, title: "Siber Güvenlik", description: "Bilgisayar ağlarını ve sistemleri koruma, saldırıları önleme ve güvenlik açıklarını kapatma becerisi." },
  { id:5, image: icon5, title: "Web Geliştirme", description: "İnternet tabanlı uygulamalar ve web siteleri oluşturma yeteneği." },
  { id:6, image: icon6, title: "Bulut Teknolojisi", description: "Veri depolama, işleme ve sunma için bulut tabanlı hizmetleri kullanabilme." },
];
