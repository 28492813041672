import React from "react";
import style from "./Teacher.module.css";
import teacherCardIcon1 from "../../../../assets/teacherCardIcon1.png";
import teacherCardIcon2 from "../../../../assets/teacherCardIcon2.png";
import teacherCardIcon3 from "../../../../assets/teacherCardIcon3.png";

const Teacher = () => {
  return (
    <div className={`${style.Teacher} m-0`}>
      <div className={`${style.line}`}></div>
      <div className={`${style.wrapper} `}>
        <div className={`${style.top} `}>
          <span>Öğretmen</span>
        </div>
        <div className={`${style.title} `}>
          <p className={`${style.titleMain} `}>
            Türkiye’nin <span className={`${style.title1} `}>K12 </span> 
            düzeyinde yetenek odaklı <span className={`${style.title2} `}>ilk eğitim platformunu</span> öğretmenlerimizin hizmetine sunuyoruz.
          </p>{" "}
        </div>
        <div className={style.cardContainer}>
          <div className={style.card1}>
            <div className={style.cardImage}>
              <img src={teacherCardIcon1} alt="logo1" />
            </div>
            <h3 className={`${style.cardTitle} m-0`}>Gelişmiş Öğretmen Araçları</h3>
            <p className={`${style.cardDescription}`}>
            Kullanıcıları, dersleri, oyunları ve daha fazlasını yönetmek için güçlü bir eğitmen arayüzü, toplu kullanıcı ekleme gibi özelliklerle birlikte benzersiz bir eğitmen platformu
            </p>
          </div>
          <div className={`${style.card2}`}>
            <div className={style.cardImage}>
              <img src={teacherCardIcon2} alt="logo2" />
            </div>
            <h3 className={`${style.cardTitle} m-0`}>Kapsamlı Platform</h3>
            <p className={`${style.cardDescription} m-0`}>
            Etkileşimli içerikler, alıştırmalar, oyunlar ve oyunlaştırma unsurları içeren kapsamlı bir e-öğrenme platformuna sahiptir.
            </p>
          </div>
          <div className={style.card3}>
            <div className={style.cardImage}>
              <img src={teacherCardIcon3} alt="logo3" />
            </div>
            <h3 className={`${style.cardTitle} m-0`}>Güçlü Müfredat</h3>
            <p className={`${style.cardDescription} m-0`}>
            Platform, teknoloji alanında kapsamlı bir eğitimi sağlamak için geniş bir bilgi yelpazesi sunar.
            </p>
          </div>
        </div>
      </div>

      <div className={`${style.moreDetail}`}>Daha fazla</div>
    </div>
  );
};

export default Teacher;
