import React from "react";
import style from "./Kurumsal.module.css";
import teacherCardIcon1 from "../../../../assets/teacherCardIcon1.png";
import teacherCardIcon2 from "../../../../assets/teacherCardIcon2.png";
import teacherCardIcon3 from "../../../../assets/teacherCardIcon3.png";

const Kurumsal = () => {
  return (
    <div className={`${style.Kurumsal} m-0`}>
      <div className={`${style.line}`}></div>
      <div className={`${style.wrapper} `}>
        <div className={`${style.top} `}>
          <span>Kurumsal</span>
        </div>
        <div className={`${style.title} `}>
          <p className={`${style.titleMain} `}>
            Türkiye’nin <span className={`${style.title1} `}>K12 </span>
            düzeyinde yetenek odaklı{" "}
            <span className={`${style.title2} `}>
              ilk eğitim platformumuzu
            </span>{" "}
            kurumlarımızın hizmetine sunuyoruz.
          </p>{" "}
        </div>
        <div className={style.cardContainer}>
          <div className={style.card1}>
            <div className={style.cardImage}>
              <img src={teacherCardIcon1} alt="logo1" />
            </div>
            <h3 className={`${style.cardTitle} m-0`}>
              Gelişmiş İlerleme Raporları{" "}
            </h3>
            <p className={`${style.cardDescription}`}>
              Öğrenciler ve öğretmenler için ayrı profiller, her biri kendi özel
              ihtiyaçları ve işlevleri için özelleştirilmiştir.
            </p>
          </div>
          <div className={`${style.card2}`}>
            <div className={style.cardImage}>
              <img src={teacherCardIcon2} alt="logo2" />
            </div>
            <h3 className={`${style.cardTitle} m-0`}>Küresel Genişleme</h3>
            <p className={`${style.cardDescription} m-0`}>
            Türkiye'nin ötesine genişleme potansiyeline sahip, genç öğrencilerin geniş küresel pazarına ulaşma imkanı.
            </p>
          </div>
          <div className={style.card3}>
            <div className={style.cardImage}>
              <img src={teacherCardIcon3} alt="logo3" />
            </div>
            <h3 className={`${style.cardTitle} m-0`}>Çoklu Kullanıcı</h3>
            <p className={`${style.cardDescription} m-0`}>
            Platform, hem öğrencilere hem de öğretmenlere hitap eden, her kullanıcı türüne özgü benzersiz özellikler sunmaktadır
            </p>
          </div>
        </div>
      </div>

      <div className={`${style.moreDetail}`}>Daha fazla</div>
    </div>
  );
};

export default Kurumsal;
