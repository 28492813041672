import React from "react";
import style from "./Section5.module.css";
import leftBg from "../../../../assets/section5LeftBg.svg";
import rightBg from "../../../../assets/section5RightBg.svg";

const Section5 = () => {
  return (
    <div className={`${style.section5} my-5`}>
      <div className={`${style.wrapper} `}>
        <div className={`${style.leftImgWrapper}`}>
          <img src={leftBg} alt="leftBg" />
        </div>
        <div className={`${style.rightImgWrapper}`}>
          <img src={rightBg} alt="rightBg" />
        </div>
        <div className={`${style.counter}`}>
          <div className={`${style.content}`}>
            <div className={`${style.box} ${style.box1}`}>
              <div className={`${style.title}`}>
                <p className={`${style.title1} m-0`}>22,000</p>
                <p className={`${style.title2} m-0`}>+</p>
              </div>
              <p className={`${style.desc} m-0`}>Kayıtlı Kullanıcı</p>
            </div>
            <div className={`${style.box} ${style.box2}`}>
              <div className={`${style.title}`}>
                <p className={`${style.title1} m-0`}>100</p>
                <p className={`${style.title2} m-0`}>%</p>
              </div>
              <p className={`${style.desc} m-0`}>Etkin Destek Hizmeti</p>
            </div>
            <div className={`${style.box} ${style.box3}`}>
              <div className={`${style.title}`}>
                <p className={`${style.title1} m-0`}>100</p>
                <p className={`${style.title2} m-0`}>%</p>
              </div>
              <p className={`${style.desc} m-0`}>Müşteri Memnuniyeti</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
