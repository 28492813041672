// src/components/Header.js
import React from 'react';
import style from './Header.module.css';
import Navigation from './Navigation';
import HeaderLogo from './HeaderLogo';

const Header = ({logo, hamburgerIcon}) => {
  return (
    <div className={`${style.header}`}>
      <div>
      <HeaderLogo logo={logo}/>
      </div>
      <div>
    <Navigation hamburgerIcon={hamburgerIcon}/>
      </div>
    </div>
  );
};

export default Header;
