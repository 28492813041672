//! Fiyatlandırma sayfasındaki alanlar burada ayarlanmıştır.
export const free = [
    "CodeMino (Sınırlı)",
    "CodeBoo (Sınırlı)",
    "Öğrenme Patikası (Sınırlı)",
    "Gelişim Paneli (Sınırlı)",
  ];
  export const premium = [
    "CodeLab",
    "CodeÇizz",
    "Öğrenme Patikaları (İleri)",
    "Siber Güvenlik",
    "Oyun Kodlama",
    "Web Programlama",
    "Yapay Zeka",
    "CodeÇizz ile Geometri",
    "Canlı Destek",
    "Kariyer Danışmanı",
  ];
  export const kurumsal = [
    "Yönetici Paneli",
    "Öğretmen Paneli",
    "Öğretmen Rehberi",
    "Platform Eğitimi",
    "Özgün Çözümler",
    "Öğrenci Takip Paneli",
    "Gelişim Projeleri",
    "Tüm Sistemler",
    "Destek Temsilcisi",
    "Kurum Danışmanı",
  ];