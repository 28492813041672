export const CheckIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M30.2393 16C30.2393 8.27083 23.9684 2 16.2393 2C8.51009 2 2.23926 8.27083 2.23926 16C2.23926 23.7292 8.51009 30 16.2393 30C23.9684 30 30.2393 23.7292 30.2393 16Z"
        stroke="#31C65B"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M23.2393 10.166L13.4393 21.8327L9.23926 17.166"
        stroke="#31C65B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  export const CrossIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M30.2393 16C30.2393 8.27083 23.9684 2 16.2393 2C8.51009 2 2.23926 8.27083 2.23926 16C2.23926 23.7292 8.51009 30 16.2393 30C23.9684 30 30.2393 23.7292 30.2393 16Z"
        stroke="#FF0000"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M11.5723 20.6654L20.9056 11.332M20.9056 20.6654L11.5723 11.332L20.9056 20.6654Z"
        stroke="#FF0000"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );